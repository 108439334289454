import './App.css';
import './Style.css';
import NavbarComponent from './Component/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './Component/Home';
import Aboutus from './Component/Aboutus';
import Courses from './Component/Courses';
import Contactus from './Component/Contactus';
import ScrollToTop from './Component/ScrollToTop';
import Footer from './Component/Footer';
import Diploma from './Component/Diploma';
import Professional from './Component/Professional';

function App() {
  return (
    <>
    <ScrollToTop/>
    <NavbarComponent/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/aboutus' element={<Aboutus/>}/>
      <Route path='/courses' element={<Courses/>}/>
      <Route path='/contactus' element={<Contactus/>}/>
      <Route path='/diploma' element={<Diploma/>}/>
      <Route path='/professional-bakery' element={<Professional/>}/>
    </Routes>
    <Footer/>
    </>
  );
}

export default App;
