// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React, { useState } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavbarComponent = () => {
    const [showCoursesDropdown, setShowCoursesDropdown] = useState(false);

    return (
        <div className="catering-section">
            <div className='navlist-chef'>
                <Navbar collapseOnSelect expand='lg' className="sidebar">

                    <Navbar.Brand href="/" className="brand-logo">
                        <img src="images/logos.png" alt="Cake & Bake" style={{ width: '100px' }} />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="justify-content-center" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="nav-menu">
                            <ul className="list_items d-flex justify-content-center">
                                <Nav.Link as={Link} to="/">Home</Nav.Link>
                                <Nav.Link as={Link} to="/aboutus">About Us</Nav.Link>
                                <Nav.Link as={Link} to="/courses">Workshops</Nav.Link>

                                {/* Dropdown for Courses with right arrow */}
                                <NavDropdown
                                    title={
                                        <>
                                            Courses <i className="fa fa-angle-right right-arrow"></i>
                                        </>
                                    }
                                    id="courses-dropdown"
                                    className="nav-link"
                                    onMouseEnter={() => setShowCoursesDropdown(true)}
                                    onMouseLeave={() => setShowCoursesDropdown(false)}
                                    show={showCoursesDropdown}
                                    style={{ padding: '0' }}>
                                    <div className="mega-menu">
                                        <NavDropdown.Item
                                            as={Link}
                                            to="/diploma"
                                        >
                                            Diploma in Bakery & <br /> Pastry Arts
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/professional-bakery">Professional Bakery & <br /> Confectionery Course</NavDropdown.Item>
                                    </div>
                                </NavDropdown>

                                <Nav.Link as={Link} to="/contactus">Contact Us</Nav.Link>
                            </ul>
                        </Nav>
                    </Navbar.Collapse>

                    <div className='contact-media'>
                        <div className="contact-info">
                            <p><i className="fa-solid fa-envelope" style={{ color: "#ffffff" }}></i> info@finesseacademyarts.com</p>
                            <p><i className="fa fa-phone" style={{ color: "#ffffff" }}></i>&nbsp;+91 9445744663</p>
                            <p><i className="fa fa-clock" style={{ color: "#ffffff" }}></i> &nbsp;Mon-Sat: 9.00 am-5.00 pm</p>
                        </div>
                        <div className="social-media">
                            <a href="https://www.facebook.com/finesseacademyarts" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook-f" style={{ color: "#d5a092" }}></i></a>
                            <a href="https://www.instagram.com/finesseacademyarts" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram" style={{ color: "#d5a092" }}></i></a>
                            <a href="#"><i className="fa-brands fa-linkedin-in" style={{ color: "#d5a092" }}></i></a>
                            <a href="#"><i className="fa-brands fa-youtube " style={{ color: "#d5a092" }}></i></a>
                        </div>
                    </div>
                </Navbar>
            </div>
        </div>
    );
}

export default NavbarComponent;
